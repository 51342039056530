import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

const BlogPost = props => {
  const post = props.data.contentfulBlogPost

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const contentType = node.data.target.fields.file["en-US"].contentType
        let alt
        let src
        switch (contentType) {
          case "image/jpeg":
            alt = node.data.target.fields.title["en-US"]
            src = node.data.target.fields.file["en-US"].url
            return <img alt={alt} src={src} />
          case "video/mp4":
            alt = node.data.target.fields.title["en-US"]
            src = node.data.target.fields.file["en-US"].url
            return <video alt={alt} src={src} controls width="100%" />
          case "audio/mpeg":
            alt = node.data.target.fields.title["en-US"]
            src = node.data.target.fields.file["en-US"].url
            return <audio alt={alt} src={src} controls />
          default:
            return (
              <div>
                Oops! We had trouble rendering your embedded asset. Type:{" "}
                {contentType}
              </div>
            )
        }
      },
    },
    renderMark: {
      [MARKS.CODE]: embedded => (
        <div dangerouslySetInnerHTML={{ __html: embedded }} />
      ),
    },
  }

  return (
    <Layout>
      <Head title={post.title} />
      <div>
        <h1>{post.title}</h1>
        <p>{post.postDate}</p>
        <p>{documentToReactComponents(post.body.json, options)}</p>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      postDate(formatString: "MMMM Do, YYYY")
      body {
        json
      }
    }
  }
`

export default BlogPost
